<template>
  <v-card>

    <div class="custom-field-select disabled">
      <label for="installment-select">{{ installments.length > 1 ? 'Número de parcelas' : 'Valor a ser pago' }}</label>
      <div class="installment-select-wrapper">
        <select name="installment" id="installment-select" v-model="installment"
          v-if="this.paymentType !== 'PayrollDeduction'" class="installment-select"
          :disabled="recurrency > 1"
          @change="showParcelamentMessage(installment.installment)"
          >
          <option v-for="installment in getInstallmentInfo" :key="'installment' + installment.id" :value="installment"
            class="input-installment">
            {{ installment.installment }}x de R$ {{ formatterHelper.formatMoney(installment.value) }}
          </option>
        </select>

        
        <select name="installment" id="installment-select" v-model="payrollInstallment" v-else
        :disabled="recurrency > 1">
          <option v-for="installment in payrollInstallments" :key="'installment' + installment.id" :value="installment"
            :selected="installment.howMany === 0">
            {{ installment.howMany > 0 ? installment.howMany + 'x - ' : '' }} {{ installment.description }}
          </option>
        </select>

        <span class="select-arrow"></span>
      </div>
      
      <div class="payment-day-information mt-2" v-if="showParcelamentDay && recurrency === 1">
        <label for="day-select">Dia do vencimento da parcela</label>
        <div class="installment-select-wrapper">
          <select name="payment-day" id="day-select" class="installment-select" v-model="paymentDay">
            <option :value="null":disabled="true" :selected="true">Selecione o dia do vencimento da parcela</option>
            <option v-for="due in dueOptions" :key="'payment-day' + due.id" :value="due.id">
              {{ due.description }}
            </option>
          </select>
          <span class="select-arrow"></span>
        </div>

      </div>
      <div class="installment-information">
        <p v-if="paymentType && taxValue !== 0 && taxOwner !== 1" id="paymentTax">{{ taxTitle }}: {{
          formatterHelper.formatMoneyWithCents(installment.total_value - checkoutValue) }}</p>
        <p><strong>Total:</strong> <strong style="color: rgba(var(--theme-primaria));">
            {{ (taxOwner === 2 && taxValue !== 0) ?
              formatterHelper.formatMoneyWithCents(installment.total_value) :
              formatterHelper.formatMoneyWithCents(checkoutValue) }}</strong></p>
      </div>
    </div>

    <slot name="cardForm"></slot>
    <slot name="cardContent"></slot>

    <v-divider></v-divider>
    <div class="custom-checkbox" @click="consentTerm">
      <v-checkbox label="Declaro que li e concordo com o Termo de Anuência" :value="consentTermCheckBox"
        color="rgba(var(--theme-primaria))" readonly></v-checkbox>
    </div>

    <v-divider></v-divider>

    <slot name="extension"></slot>
    <AucAlert
      :key="alert"
      ref="parcelamentMessage"
      :title="getParcelamentMessageText"
      confirmButtonText="Ok"
    />
  </v-card>
  
</template>

<script>
// VUEX
import { mapGetters, mapActions } from "vuex";

// Helpers
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Enums
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
import { mounted } from "vue-barcode";

// Components
import AucAlert from "@/components/AucAlert.vue";

export default {
  props: {
    installments: Number,
    paymentMethod: String,
    payrollInstallments: Array,
    tax: Number,
  },
  components: {
    AucAlert,
  },
  watch: {
    installments(installmentsArray) {
      this.installment = installmentsArray[0]
    },
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      commonHelper: CommonHelper,
      cardId: '',
      showParcelamentDay: false,
      dueOptions: [
        { id: 1, description: "Vencimento todo dia 1" },
        { id: 10, description: "Vencimento todo dia 10" },
        { id: 20, description: "Vencimento todo dia 20" },
      ],
      PaymentMethodProvider: PaymentMethodProvider,
    };
  },
  computed: {
    ...mapGetters([
      "cartLocal",
      "checkoutValue",
      "installments",
      "selectedInstallment",
      "consentTermIsChecked",
      "checkoutRequest",
      "paymentType",
      "paymentTaxes",
      "paymentMethodProviders",
      "payrollInstallment",
      "campaignLandingPageConfig",
      "paymentInstallments",
      "recurrency"
    ]),
    taxValue() {
      if (this.paymentType && this.paymentTaxes && this.paymentType !== 'PayrollDeduction')
        return this.installment.total_value - this.checkoutValue;
      else
        return 0
    },
    taxOwner() {
      if (this.paymentType && this.paymentTaxes && this.paymentType !== 'PayrollDeduction')
        return this.paymentTaxes[this.paymentType].taxOwner
      else
        return 1
    },
    taxTitle() {
      switch (this.paymentType) {
        case 'CreditCard':
          return 'Taxa do Cartão'
        case 'Pix':
          return 'Taxa do Pix'
        case 'Ticket':
          return 'Taxa do Boleto'
        default:
          return 'Taxa'
      }
    },
    getInstallmentInfo() {
      try {
        if(!this.paymentInstallments) return []
        switch (this.paymentType) {
          case 'CreditCard':
            return this.paymentInstallments.creditcard.installmentInfo
          case 'Pix':
            return this.paymentInstallments.pix.installmentInfo
          case 'Ticket':
            return this.paymentInstallments.ticket.installmentInfo
          case 'DebitAccount':
            return this.paymentInstallments.debitAccount.installmentInfo
          default:
            return []
        }
      } catch (err) {
        return []
      }
    },
    getParcelamentMessageText(){
        var currentYear = new Date().getFullYear();
        return `Somente os valores pagos até o dia 26/12/${currentYear} poderão ser deduzidos em seu Imposto de Renda PF ${currentYear + 1} (exercício ${currentYear}).
        Caso queira que todo o valor doado seja deduzido no IR do próximo ano, por favor, efetue todos os pagamentos até essa data.
        As informações referentes aos valores que devem ser considerados para abatimento do IRPF ${currentYear + 1} serão enviadas por e-mail, no Informe de Doações.
        `;
    },
    filledCheckoutRequestCondition() {
      let providerThatUseTableCreditCardsInformation = [this.PaymentMethodProvider.Asaas]
      let currentProvider = this.paymentMethodProviders.creditCard

      if (providerThatUseTableCreditCardsInformation.includes(currentProvider)) {
        return (this.checkoutRequest.creditCardInfo.cardNumber &&
          this.checkoutRequest.creditCardInfo.cvv &&
          this.checkoutRequest.creditCardInfo.holder.name) || this.paymentType !== 'CreditCard'
      } else {
        return ((this.checkoutRequest.creditCardInfo.cardNumber &&
          this.checkoutRequest.creditCardInfo.cvv &&
          this.checkoutRequest.creditCardInfo.expirationMonth &&
          this.checkoutRequest.creditCardInfo.expirationYear &&
          this.checkoutRequest.creditCardInfo.holder.name) || this.paymentType !== 'CreditCard')
      }
    },
    consentTermCheckBox: {
      get() {
        return this.$store.getters.consentTermIsChecked;
      },
      set(value) {
        this.setConsentTermIsChecked(value)
      }
    },
    installment: {
      get() {
        return this.$store.getters.selectedInstallment;
      },
      set(value) {
        this.setInstallment(value);
        if ((this.paymentType === 'Pix' ||
          this.paymentType === 'Ticket' ||
          this.paymentType === 'PayrollDeduction') &&
          value &&
          value.installment > 1
        )
          this.showParcelamentDay = true
        else this.showParcelamentDay = false
      }
    },
    payrollInstallment: {
      get() {
        return this.$store.getters.payrollInstallment;
      },
      set(value) {
        this.setPayrollInstallment(value)
        this.setInstallment(value)
      }
    },
    paymentDay: {
      get() {
        return this.$store.getters.paymentDay;
      },
      set(value) {
        this.setPaymentDay(value);
      }
    },
    installmentsArray() {
      return [...Array(this.installments).keys()];
    },
    filledCheckoutRequest() {
      return ((this.checkoutRequest.creditCardInfo.cardNumber &&
        this.checkoutRequest.creditCardInfo.cvv &&
        (this.checkoutRequest.creditCardInfo.expirationMonth !== '0') &&
        (this.checkoutRequest.creditCardInfo.expirationYear !== '0') &&
        this.checkoutRequest.creditCardInfo.holder.name) || this.paymentType !== 'CreditCard')
    }
  },
  methods: {
    ...mapActions([
      "setInstallment",
      "setConsentTermIsChecked",
      "setConsentTermDialog",
      "setPaymentDay",
      "setPayrollInstallment",
    ]),
    consentTerm() {
      if (!this.consentTermCheckBox && this.paymentMethod !== 'creditCard') this.setConsentTermDialog(true)
      else if (this.consentTermCheckBox && this.paymentMethod !== 'creditCard') this.setConsentTermIsChecked(false)
      else if (!this.filledCheckoutRequest && this.paymentMethod === 'creditCard' && !this.filledCheckoutRequestCondition) {
        this.commonHelper.swal("É necessário preencher todos os dados do cartão antes de prosseguir com o termo de anuência");
        this.setConsentTermIsChecked(false)
      }
      else if (!this.consentTermCheckBox && this.paymentMethod === 'creditCard') this.setConsentTermDialog(true)
      else if (this.consentTermCheckBox) this.setConsentTermIsChecked(false)
    },
    showParcelamentMessage(parcelament){
        let month = new Date().getMonth() + 1;
        let totalMonth = month + parseInt(parcelament);
        if (totalMonth > 12 && this.campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Incentivada) {
            this.$refs.parcelamentMessage.open();
            return
        }
    },
  },
};
</script>


<style scoped>
.installment-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.installment-select {
  width: 100%;
  margin-top: 4px !important;
  padding: 10px;
  border: 1.5px solid #ccc !important;
  background-color: #fff !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  font-size: 16px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}
</style>